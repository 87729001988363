
import CloudFun, {
  defineComponent,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue"
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    VueQrcode
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: "站點",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "站點清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "站點名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Area.Name",
          title: "區域",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
            return model.dispatch("station/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("station/query") : undefined,
        save: model
          ? params => model.dispatch("station/save", params)
          : undefined
      },
      modalConfig: { showFooter: true, width: "60%" }
      //   treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "AreaId",
          title: "區域",
          span: 12,
          titleWidth: 100,
          slots: { default: "column-area-id" }
        },
        {
          field: "Address",
          title: "地址",
          span: 24,
          slots: { default: "column-address" }
        },
        {
          field: "Longitude",
          title: "經度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入經度", clearable: true, type: "number" }
          }
        },
        {
          field: "Latitude",
          title: "緯度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入緯度", clearable: true, type: "number" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "ShortUrl",
          title: "Qrcode",
          span: 24,
          slots: { default: "column-qrcode" }
        },
        // {
        //   field: "StartTime",
        //   title: "營業開始時間",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "time", placeholder: "請輸入開始時間" }
        //   }
        // },
        // {
        //   field: "EndTime",
        //   title: "營業結束時間",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "time", placeholder: "請輸入結束時間" }
        //   }
        // },
        // {
        //   field: "IntervalTime",
        //   title: "租借時間刻度(分)",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "number", placeholder: "請輸入時間刻度" }
        //   }
        // },
      ],
      rules: {
        Name: [{ type: "string", required: true }],
        AreaId: [{ required: true }],
        Longitude: [{ required: true }],
        Latitude: [{ required: true }],
      }
    };

    const areaSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇區域",
      textField: "Name",
      valueField: "Id",
      // formatText: async (row: any) => {
      //   let entity = await model!.dispatch("area/find", row.Id); // eslint-disable-line
      //   let text = entity.Name;
      //   while (entity.Area) {
      //     entity = entity.Area;
      //     text = `${entity.Name}>${text}`;
      //   }
      //   return text;
      // },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("area/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("area/query", params); // eslint-disable-line
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      areaSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
